.static-v1 {
	margin-top: var(--page-margin-top);

	&.impressum-v1,
	&.privacy-v1,
	&.about-us-v1 {
		.inner-banner {
			position: fixed;
			width: 100%;
			top: 0;
		}

		.main-wraper {
			text-align: left;
		}
	}

	&.about-us-v1 {}

	.contact-map {
		#map {
			height: 400px !important;
		}
	}

	.gmapc-bl {
		position: reletive;

		.gmap-address {
			position: absolute;
			bottom: 15px;
			z-index: 1;
			background: #fff;
			padding: 15px;
			left: 30px;
			font-size: 14px;

			@media (max-width:576px) {
				padding: 8px;
				left: 30px;
				font-size: 12px;

				left: 20px;
				bottom: 5px;
			}
		}
	}

	.main-wraper {
		margin-top: 600px;
		background: #fff;
		position: relative;
		padding-bottom: 0;
		text-align: center;

		@media (max-width:992px) {
			margin-top: 450px;
		}

		.heading1 {
			padding-top: 50px;

			@media (max-width:767px) {
				font-size: 22px;
				padding-left: 0;
				padding-right: 0;
			}
		}

		p {
			@media (max-width:767px) {
				word-break: break-word;
			}
		}
	}


}
.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

	.video-col {
		iframe {
			width: 100%;
			height: 755px;
			border: 0;

			@media (max-width:1199px) {
				height: 525px;
			}

			@media (max-width:767px) {
				height: 285px;
			}

			@media (max-width:576px) {
				height: 195px;
			}
		}

	}

	.contact-map {
		height: 450px;

		@media (max-width:576px) {
			height: 260px;
		}
	}

	.gmapc-bl {
		position: reletive;

		.gmap-address {
			position: absolute;
			bottom: 15px;
			z-index: 1;
			background: #fff;
			padding: 15px;
			left: 30px;
			font-size: 14px;

			@media (max-width:576px) {
				padding: 8px;
				left: 30px;
				font-size: 12px;

				left: 20px;
				bottom: 5px;
			}
		}
	}
}
.unit-card-v1 {

	.content {
		padding: 15px;
		position: relative;
		margin: 0 25px;
		background-color: var(--white);
		top: -35px;
		border-radius: 5px;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .2);

		.unit-title-bl {
			margin: 0 0 15px;
		}
	}

	.region-type {
		font-size: 13px;
		color: var(--color-secondary);
	}

	.fa-map-marker-alt {
		padding-left: -10px;
		padding-right: 5px;
	}

	.label-container {
		display: flex;
		justify-content: space-between;
		/*	flex-direction: row-reverse;*/
		align-items: center;
		margin: 15px -15px 0;
		padding: 10px 15px 0;
		border-top: 1px solid var(--color-grey-light);


		p {
			margin: 0;

			strong {
				font-size: 21px;
				font-weight: 600;
				color: var(--color-black);

				@media (max-width:992px) {
					font-size: 18px;
				}
			}

			.price-bl {
				display: inline-block;

				i.fa {
					color: var(--color-grey-dark);
					font-size: 12px;
					margin-left: 5px;
				}

				.price-start {
					display: inline-block;
				}
			}

			span {
				font-size: 12px;
				color: var(--color-grey-dark);
				display: block;
				line-height: 18px;
			}
		}

		a {
			color: var(--color-black);
		}

		.price-info {

			i {
				color: var(--color-grey-dark);
				font-size: 12px;
				margin-left: 5px;
			}
		}


		.text-link {
			font-size: 13px;
			font-weight: 700;
			color: var(--color-primary);
		}

		.label {
			padding: 5px 10px;
			font-weight: 500;
			width: fit-content;
			font-weight: 600;
			color: initial;
		}

		.book {
			cursor: pointer;
			border-radius: 5px;
			transition: 0.25s;
			text-decoration: none;

			&:hover {
				background-color: var(--color-primary);
				color: var(--color-white);
				text-decoration: none;
			}

			span:nth-of-type(1) {
				font-size: 10px;
			}
		}

		.customer-rating {
			font-weight: 500;
			color: initial;
			display: flex;
			flex-direction: column;
			align-items: center;

			span:nth-child(2) {
				font-size: 9px;
			}
		}
	}

	.object-name {
		font-size: var(--font-size-h3);

	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		i {
			opacity: .2;
		}
	}


	.comodity {
		padding: 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.image-wrap {
		position: relative;

		>a {
			position: relative;
			border-radius: 8px;
			display: block;
			box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
			overflow: hidden;
		}


		img {
			width: 100%;
			transition: 0.5s;

			&:hover {

				filter: brightness(0.7);

			}
		}

		.stars {
			color: #DAA520;
			background-color: white;
			display: flex;
			align-items: center;
		}

		.rules {
			background-color: white;
			padding: 0;
			display: flex;


			i {
				font-size: 16px;
				color: var(--color-black);
				font-style: normal;
				width: 28px;
				height: 28px;
				background-color: var(--color-grey-light);
				border-radius: 50%;
				display: -webkit-box;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;
				position: relative;
				margin-left: 5px;

				&.cross {
					position: relative;

					&:after {
						width: 1.5px;
						height: 100%;
						position: absolute;
						content: "";
						background-color: var(--color-black);
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
				}

			}
		}

		.favorite {
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
		}
	}


	.ribbon {
		position: absolute;
		left: 10px;
		background: var(--color-red);
		top: 10px;
		padding: 3px 20px;
		border-radius: 10px;
		font-size: 13px;
		font-weight: 600;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
	}

}
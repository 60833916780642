.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);
	/*overflow: hidden;*/

	&:before {
		background-color: rgba(0, 0, 0, 0.2);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		pointer-events: none;
		content: "";
	}

	.picture {
		height: var(--index-slide-show-height);
		overflow: hidden;
	}

	@media (min-width:768px) {
		#banner-video {
			width: 100%;
		}
	}

	@media (max-width: 767px) {
		#banner-video {
			height: 100vh;
		}
	}

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		/*margin-top: var(--index-slide-show-margin-top);*/

		.item {
			height: var(--index-slide-show-height);

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			overflow: hidden;

			@media (max-height: 600px) {
				min-height: 680px;
			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}

			video {
				width: 100%;

				@media (max-width:1200px) {
					width: auto;
					height: 100%;
				}
			}
		}


	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		display: none;
		position: absolute;
		top: 29vh;
		font-family: 'Poppins', cursive;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-height: 600px) {
			top: 200px;

		}

		.hero-text {
			text-align: center;
			width: 70%;
			margin: 0 auto;
			padding: 0 15px;

			@media (max-width:767px) {
				width: 100%;
			}

			img {
				filter: invert(100%) brightness(100%);
				width: 70%;

				@media (max-width:767px) {
					width: 85%;
				}
			}

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 5vw;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 80px;
				}

				@media (max-width: 1140px) {
					font-size: 57px;
				}

				@media (max-width: 992px) {
					font-size: 4rem;
					line-height: 4rem;
				}

				@media (max-width: 576px) {
					font-size: 3rem;
					line-height: 3rem;
				}

				@media (max-height: 600px) {
					font-size: 2.75rem;
					line-height: 2.75rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
				display: block;

				@media (max-width:767px) {
					font-size: 1.5rem;
				}
			}
		}

	}


}
.unit-pricelist-v1 {
	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}

	.showLess {
		position: relative;

		.widget-block {
			height: 175px;
			overflow: hidden;

			@media (max-width:767px) {
				height: 209px;
			}
		}

		&.showFull {
			.widget-block {
				height: auto;
				overflow: visible;
			}

			.widget {
				display: block;
			}


			.hide-show-btn {

				&:after {
					content: "\f106";
				}
			}
		}

		.widget {
			display: none;
		}

		.first {
			display: block;
		}

		.hide-show-btn {
			position: absolute;
			bottom: -14px;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 5px 15px;
			background-color: var(--white);
			display: inline-block;
			border: 1px solid var(--btn-border-color-hide-show);
			font-size: var(--font-size-md);

			&:focus,
			&:hover {
				text-decoration: none;
			}

			&:after {
				content: "\f107";
				margin-left: 10px;
				margin-left: 5px;
				font-family: fontawesome;
			}
		}
	}
}
.parallax-v2 {
	position: relative;
	background-image: url(RESOURCE/img/uber-uns-parallx-img.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	&:before {
		background-color: var(--white);
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		content: "";

		@media (max-width:992px) {
			height: 80px;
		}
	}

	&:after {
		background-color: var(--white);
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";

		@media (max-width:992px) {
			height: 80px;
		}
	}

	.center-col {
		display: flex;
		justify-content: center;
	}

	.parallax-box {
		background-color: var(--color-grey-dark);
		padding: 50px;
		/*min-height: 400px;*/
		text-align: center;
		position: relative;
		z-index: 1;

		@media (max-width:767px) {
			padding: 30px;
		}

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-black);
		}

		.widget-block {
			background: transparent;
			border: 0;
			padding: 0;
			height: 480px;
			overflow: hidden;

			@media (max-width:1199px) {
				height: 460px;
			}

			@media (max-width:575px) {
				height: 452px;
			}
		}
	}

	.showcontent-bl {
		margin-bottom: 15px;

		&.showtext {
			.widget-block {
				height: auto;
				overflow: visible;
			}

			.btn-show-hide {



				.showless {
					display: block;
				}

				.showmore {
					display: none;
				}
			}
		}

		.btn-show-hide {
			color: var(--color-black);
			text-decoration: underline;

			.showless {
				display: none
			}
		}

	}
}
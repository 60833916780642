.impressum-v1 {
	margin-top: var(--page-margin-top);

	p:last-child() {
		margin-bottom: 0;
	}
}

.voffice-section {
	position: relative;
	background: var(--color-white);
	border-top: 1px solid var(--color-grey-dark);
	padding-top: 30px;

}

.no-margin {
	margin: 0;
}
.parallax-v1 {
	position: relative;
	background-image: url(RESOURCE/img/parallax-bg1.jpg);
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	&:before {
		background-color: var(--white);
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		content: "";

		@media (max-width:992px) {
			height: 80px;
		}
	}

	&:after {
		background-color: var(--white);
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";

		@media (max-width:992px) {
			height: 80px;
		}
	}

	.heading1 {
		font-size: 35px;

		@media (max-width:767px) {
			font-size: 25px;
		}
	}

	.btn {
		font-size: 20px;
		margin-top: 40px;
	}

	.center-col {
		display: flex;
		justify-content: center;
	}

	.parallax-box {
		background-color: var(--color-grey-dark);
		padding: 50px;
		/*min-height: 400px;*/
		text-align: center;
		position: relative;
		z-index: 1;

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-black);
			font-size: 24px;

			@media (max-width:767px) {
				font-size: 20px;
			}

		}
	}

	.container {
		@media (max-width:992px) {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}
}
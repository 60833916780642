.parallax-v3 {
	position: relative;
	background-image: url(RESOURCE/img/parallax-bg3.jpg);


	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@media (max-width:992px) {
		background-attachment: scroll;
	}

	&:before {
		background-color: var(--white);
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		content: "";

		@media (max-width:992px) {
			height: 80px;
		}
	}

	&:after {
		background-color: var(--white);
		height: 90px;
		width: 100%;
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";

		@media (max-width:992px) {
			height: 80px;
		}
	}

	.center-col {
		display: flex;
		justify-content: center;
	}

	.parallax-box {
		background-color: var(--color-grey-dark);
		padding: 50px;
		/*min-height: 400px;*/
		text-align: center;
		position: relative;
		z-index: 1;

		@media (max-width:767px) {
			padding: 30px;
		}

		.title {
			color: var(--color-white);

		}

		.text {
			color: var(--color-black);
		}

		.dlg {
			.box {
				height: 360px;
				position: absolute;
				top: 150px;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 90%;

				@media (max-width:767px) {
					height: 253px;
				}

				.header {
					border-bottom: 0;

					.header-close {
						z-index: 9;
					}
				}
			}

		}

		.owl-nav {
			font-size: 40px;
			margin: 0 10px;
		}
	}

	.gallery-bl {
		a {
			display: none;

		}
	}

	.owl-item {
		img {
			height: auto;
		}
	}
}